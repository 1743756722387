// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-2-js": () => import("./../../../src/pages/blog2.js" /* webpackChunkName: "component---src-pages-blog-2-js" */),
  "component---src-pages-contactme-js": () => import("./../../../src/pages/contactme.js" /* webpackChunkName: "component---src-pages-contactme-js" */),
  "component---src-pages-examples-for-artificial-intelligence-in-luxury-js": () => import("./../../../src/pages/examples-for-artificial-intelligence-in-luxury.js" /* webpackChunkName: "component---src-pages-examples-for-artificial-intelligence-in-luxury-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-luxury-circulareconomy-js": () => import("./../../../src/pages/luxury-circulareconomy.js" /* webpackChunkName: "component---src-pages-luxury-circulareconomy-js" */),
  "component---src-pages-luxury-customer-sentimentanalysis-js": () => import("./../../../src/pages/luxury-customer-sentimentanalysis.js" /* webpackChunkName: "component---src-pages-luxury-customer-sentimentanalysis-js" */),
  "component---src-pages-luxury-customeranalysis-js": () => import("./../../../src/pages/luxury-customeranalysis.js" /* webpackChunkName: "component---src-pages-luxury-customeranalysis-js" */),
  "component---src-pages-luxury-diversity-and-inclusion-js": () => import("./../../../src/pages/luxury-diversity-and-inclusion.js" /* webpackChunkName: "component---src-pages-luxury-diversity-and-inclusion-js" */),
  "component---src-pages-luxury-keynotespeaker-js": () => import("./../../../src/pages/luxury-keynotespeaker.js" /* webpackChunkName: "component---src-pages-luxury-keynotespeaker-js" */),
  "component---src-pages-luxury-thoughleadership-js": () => import("./../../../src/pages/luxury-thoughleadership.js" /* webpackChunkName: "component---src-pages-luxury-thoughleadership-js" */),
  "component---src-pages-mindfulness-luxuryleadership-js": () => import("./../../../src/pages/mindfulness-luxuryleadership.js" /* webpackChunkName: "component---src-pages-mindfulness-luxuryleadership-js" */),
  "component---src-pages-my-heritage-js": () => import("./../../../src/pages/my-heritage.js" /* webpackChunkName: "component---src-pages-my-heritage-js" */),
  "component---src-pages-newpage-js": () => import("./../../../src/pages/newpage.js" /* webpackChunkName: "component---src-pages-newpage-js" */),
  "component---src-pages-newsletter-signup-js": () => import("./../../../src/pages/newsletter-signup.js" /* webpackChunkName: "component---src-pages-newsletter-signup-js" */),
  "component---src-pages-newsletter-subscription-confirmed-js": () => import("./../../../src/pages/newsletter/subscription-confirmed.js" /* webpackChunkName: "component---src-pages-newsletter-subscription-confirmed-js" */),
  "component---src-pages-newsletter-thankyou-js": () => import("./../../../src/pages/newsletter/thankyou.js" /* webpackChunkName: "component---src-pages-newsletter-thankyou-js" */),
  "component---src-pages-newsletter-update-preferences-js": () => import("./../../../src/pages/newsletter/update-preferences.js" /* webpackChunkName: "component---src-pages-newsletter-update-preferences-js" */),
  "component---src-pages-nuheritage-js": () => import("./../../../src/pages/nuheritage.js" /* webpackChunkName: "component---src-pages-nuheritage-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-press-releases-js": () => import("./../../../src/pages/press-releases.js" /* webpackChunkName: "component---src-pages-press-releases-js" */),
  "component---src-pages-press-releases-luxury-diversity-and-inclusion-js": () => import("./../../../src/pages/press-releases/luxury-diversity-and-inclusion.js" /* webpackChunkName: "component---src-pages-press-releases-luxury-diversity-and-inclusion-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/PrivacyPolicy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-reports-js": () => import("./../../../src/pages/reports.js" /* webpackChunkName: "component---src-pages-reports-js" */),
  "component---src-pages-sitemap-js": () => import("./../../../src/pages/sitemap.js" /* webpackChunkName: "component---src-pages-sitemap-js" */),
  "component---src-pages-the-fresh-kid-js": () => import("./../../../src/pages/the-fresh-kid.js" /* webpackChunkName: "component---src-pages-the-fresh-kid-js" */),
  "component---src-pages-what-is-luxury-circulareconomy-js": () => import("./../../../src/pages/what-is-luxury-circulareconomy.js" /* webpackChunkName: "component---src-pages-what-is-luxury-circulareconomy-js" */),
  "component---src-pages-what-is-luxury-digitaltransformation-js": () => import("./../../../src/pages/what-is-luxury-digitaltransformation.js" /* webpackChunkName: "component---src-pages-what-is-luxury-digitaltransformation-js" */)
}

